// Bootstrap overrides
//
// Copy variables from `_variables.scss` to this file to override default values
// without modifying source files.


// Colors
//
// Grayscale and brand colors for use across Bootstrap.

// Reassign color vars to semantic color scheme
$brand-primary: #000;
$brand-secondary: #82b81a;
$middlegrey: #9da1ab;
$darkgrey: #515151;
$lightgrey: #cdcdcd;
$xlightgrey: #e6e6e6;
$highlight-color: #d11216;
$blue: #0070b8;
$orange: #f6a925;
$pink: #c4004a;
$green: #82b81a;
$purple: #a02283;


// Body
//
// Settings for the `<body>` element.

$body-bg: #fff !default;
$body-color: #9fabb8 !default;


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px
) !default;


// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: 'Montserrat', sans-serif;
$font-family-serif:'Playfair', serif;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`

$line-height-base: 2 !default;

$font-size-h1: 2.25rem !default;
$font-size-h2: 1.5rem !default;
$font-size-h3: 1.4rem !default;
$font-size-h4: 1.25rem !default;
$font-size-h5: 1.15rem !default;
$font-size-h6: 1rem !default;

$headings-font-weight:   300 !default;
$headings-line-height:   1.5 !default;
$headings-color:         $brand-secondary !default;