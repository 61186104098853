// -------------------------------------------------------------
//   Main header
//   Header styles
// -------------------------------------------------------------
body.node-type-accueil {
	header {
		background: none;
		&.scrolled {
			background: #000;
		}
	}
}
header {
	-webkit-box-shadow: inset 0px 98px 113px -38px rgba(0,0,0,0.76);
	-moz-box-shadow: inset 0px 98px 113px -38px rgba(0,0,0,0.76);
	box-shadow: inset 0px 98px 113px -38px rgba(0,0,0,0.76);
	padding: 10px 0 0 0;
	background: #000;
	@include transition(background .5s, padding .2s);
	z-index: 2;
	position: fixed;
  top: 0;
  left: 0;
  width: 100%;
	.navigation {
		display: flex;
		justify-content: space-between;
		a.fb-link {
			font-family: $font-family-serif;
			font-size: 16px;
			font-style: italic;
			color: #fff;
			padding: 22px 0 0 0;
			&:after {
				content:'\f09a ';
				display: inline-block;
				background: #fff;
				font-family: FontAwesome;
				font-size: 18px;
				font-style: normal;
				color: #000;
				width: 30px;
				height: 30px;
				margin-left: 10px;
				padding: 5px 0 0 0;
				position: relative;
				top: 5px;
				text-align: center;
				border-radius: 50px;
				-moz-border-radius: 50px;
				-webkit-border-radius: 50px;
			}
		}
	}
	&.scrolled {
		background: #000;
		box-shadow: none;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		padding: 10px 0 0 0;
		@include transition(background .5s, padding .2s);
	}
}
