section#video {
  h1 {
    color: #fff;
    font-size: 60px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    padding-top: 150px;
    text-shadow: 5px 2px 2px rgba(0, 0, 0, .6);
  }
  .home-categories {
    position: relative;
    height: 980px;
    margin-top: 60px;
    h2 {
      color: #fff;
      font-size: 20px;
      position: absolute;
      left: 370px;
      top: 150px;
      text-align: right;
      line-height: 26px;
      font-weight: 600;
      text-shadow: 5px 2px 2px rgba(0, 0, 0, .6);
    }
    .home-category {
      position: absolute;
      background-repeat: no-repeat;
      background-size: cover;
      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include transition (all, .2s);
        h3 {
          color: #fff;
          background: #000;
          display: inline-block;
          padding: 3px;
          text-transform: uppercase;
          font-size: 18px;
          font-weight: 700;
          letter-spacing: .10em;
          position: absolute;
        }
        .more-link {
          visibility: hidden;
          color: #fff;
          font-size: 13px;
          font-weight: 600;
          text-decoration: underline;
          position: absolute;
          -webkit-transform: scale(0.05);
          -moz-transform: scale(0.05);
          transform: scale(0.05);
        }
      }
      &.bleu {
        width: 300px;
        height: 300px;
        z-index: 2;
        top: 488px;
        left: 12px;
        h3 {
          bottom: 20px;
          left: 20px;
          @include transition (all, .2s);
        }
        .more-link {
          bottom: 200px;
          left: 100px;
          @include transition (all, .2s);
        }
        &:hover {
          .overlay {
            background: rgba(0,112,184,.5);
            @include transition (all, .2s);
            h3 {
              bottom: 100px;
              background: none;
              @include transition (all, .2s);
            }
            .more-link {
              visibility: visible;
              left: 20px;
              bottom: 60px;
              -webkit-transform: scale(1);
              -moz-transform: scale(1);
              transform: scale(1);
              @include transition (all, .2s);
              &:hover {
                text-decoration: none;
              }
            }
          }
        }
      }
      &.violet {
        display: none;
      }
      &.vert {
        width: 600px;
        height: 600px;
        top: 338px;
        left: 312px;
        z-index: 1;
        h3 {
          top: 60px;
          left: 40px;
          width: 230px;
          @include transition (all, .2s);
        }
        .more-link {
          top: 200px;
          left: 100px;
          @include transition (all, .2s);
        }
        &:hover {
          .overlay {
            background: rgba(130,184,26,.5);
            @include transition (all, .2s);
            h3 {
              top: 20px;
              background: none;
              @include transition (all, .2s);
            }
            .more-link {
              visibility: visible;
              left: 40px;
              top: 100px;
              -webkit-transform: scale(1);
              -moz-transform: scale(1);
              transform: scale(1);
              @include transition (all, .2s);
              &:hover {
                text-decoration: none;
              }
            }
          }
        }
      }
      &.jaune {
        width: 300px;
        height: 300px;
        top: 38px;
        left: 612px;
        z-index: 10;
        h3 {
          bottom: 20px;
          left: 20px;
          @include transition (all, .2s);
        }
        .more-link {
          bottom: 200px;
          left: 100px;
          @include transition (all, .2s);
        }
        &:hover {
          .overlay {
            background: rgba(246,169,37,.5);
            @include transition (all, .2s);
            h3 {
              bottom: 100px;
              background: none;
              @include transition (all, .2s);
            }
            .more-link {
              visibility: visible;
              left: 20px;
              bottom: 60px;
              -webkit-transform: scale(1);
              -moz-transform: scale(1);
              transform: scale(1);
              @include transition (all, .2s);
              &:hover {
                text-decoration: none;
              }
            }
          }
        }
      }
      &.rouge {
        width: 300px;
        height: 300px;
        top: 188px;
        left: 912px;
        z-index: 10;
        h3 {
          bottom: 20px;
          right: 20px;
          text-align: right;
          width: 200px;
          @include transition (all, .2s);
        }
        .more-link {
          bottom: 200px;
          right: 100px;
          @include transition (all, .2s);
        }
        &:hover {
          .overlay {
            background: rgba(196,0,74,.5);
            @include transition (all, .2s);
            h3 {
              bottom: 60px;
              background: none;
              @include transition (all, .2s);
            }
            .more-link {
              visibility: visible;
              right: 20px;
              bottom: 20px;
              -webkit-transform: scale(1);
              -moz-transform: scale(1);
              transform: scale(1);
              @include transition (all, .2s);
              &:hover {
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }
}
.cta {
  padding: 5px 0;
  border-top: 1px solid $xlightgrey;
  border-bottom: 1px solid $xlightgrey;
  margin-top: 20px;
  text-align: center;
  p {
    font-size: 18px;
    line-height: 30px;
    color: #000;
    margin-bottom: 20px;
  }
  .inner-cta {
    border-top: 1px solid $xlightgrey;
    border-bottom: 1px solid $xlightgrey;
    padding: 40px;
  }
}
#references {
  margin-top: 50px;
  h2 {
    font-weight: 700;
    font-size: 22px;
    padding-left: 150px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
}
.swiper-container {
  width: 100%;
  height: 570px;
  margin: 20px auto;
  position: relative;
  .pag-box {
    position: absolute;
    background: #fff;
    bottom: 240px;
    left: 175px;
    padding: 20px;
    z-index: 99;
    .swiper-button-prev {
      position: static;
      background: none;
      margin-top: 0;
      height: auto;
      &:before {
        content:'\f177';
        font-size: 25px;
        font-family: FontAwesome;
        color: #000;
      }
    }
    .swiper-button-next {
      position: static;
      background: none;
      height: auto;
      margin-top: 0;
      &:before {
        content:'\f178';
        font-size: 25px;
        font-family: FontAwesome;
        color: #000;
      }
    }
  }
  .swiper-pagination {
    position: static;
    font-size: 12px;
    font-style: italic;
  }
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  width: 60%;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-transform: scale-y(1, 0.75);
    -moz-transform: scale(1, 0.75);
    transform: scale(1, 0.75);
    opacity: 0.2;
    &.swiper-slide-active {
      opacity: 1;
      -webkit-transform: scale-y(1, 1);
      -moz-transform: scale(1, 1);
      transform: scale(1, 1);
      &:hover {
        .overlay {
          visibility: visible;
        }
      }
    }
  }
  .home-reference {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 960px;
    height: 570px;
    position: relative;
    .overlay {
      position: absolute;
      visibility: hidden;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,.2);
      color: #fff;
      text-align: left;
      padding: 40px;
      font-size: 13px;
      line-height: 26px;
      @include transition (all, .2s);
      h3 {
        color: #fff;
      }
      a {
        color: #fff;
        display: block;
        text-decoration: underline;
        margin-top: 10px;
        font-size: 14px;
        &:hover {
          text-decoration: none;
        }
      }
    }
    &:hover {
      .overlay {
        background: rgba(0,0,0,.8);
        @include transition (all, .2s);
      }
    }
  }
