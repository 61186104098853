// -------------------------------------------------------------
//   Main footer
//   Footer styles
// -------------------------------------------------------------

#footer {
  padding: 40px 0;
  text-align: center;
  color: $middlegrey;
  text-align: left;
  .footer-right {
    text-align: right;
  }
  p {
    font-size: 13px;
  }
  a {
    color: $middlegrey;
    font-style: italic;
  }
}