.news-list {
	border: 1px solid $xlightgrey;
	margin-top: 40px;
	min-height: 550px;
	@include transition (all .2s);
	&:hover {
		-webkit-box-shadow: 0px 0px 21px 0px rgba(0,0,0,0.29);
		-moz-box-shadow: 0px 0px 21px 0px rgba(0,0,0,0.29);
		box-shadow: 0px 0px 21px 0px rgba(0,0,0,0.29);
	}
	.pic {
		height: 220px;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}
	.news-content {
		padding: 20px;
		h2 {
			font-size: 18px;
			font-weight: 600;
			color: $brand-primary;
			margin-bottom: 10px;
		}
		p {
			color: $darkgrey;
			margin-top: 10px;
			font-size: 16px;
			line-height: 30px;
		}
	}
}
