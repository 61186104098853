// Breadcrumb
// =============================================================================

.breadcrumb {
  font-family: $font-family-sans-serif;
  font-size: 12px;
  margin-bottom: 10px;
  list-style: none;
  background: none;
  padding-left: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  li {
    display: inline-block;
    color: #fff;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: normal;
    & + li {
      &:before {
        content: '/';
        padding: 0 5px;
      }
    }
  }
  a {
    color: $middlegrey;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}