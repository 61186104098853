.evidence {
	padding: 20px;
	border-top: 5px solid $xlightgrey;
	border-bottom: 5px solid $xlightgrey;
	font-size: 14px;
	line-height: 30px;
	h2 {
		text-align: center;
		font-weight: 600;
		text-transform: uppercase;
	}
}