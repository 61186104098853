// -------------------------------------------------------------
//   Navigation
// -------------------------------------------------------------

nav#main-nav {
  @include transition(all .2s ease-in-out);
  .showme_on_mobile {
    display: none
  }
  ul {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    padding-top: 30px;
    li {
      font-size: 14px;
      padding: 0px 20px 30px 20px;
      list-style: none;
      margin: 0;
      a, .nolink {
        color: #fff;
        font-weight: 600;
        letter-spacing: 0.5px;
        font-size: 14px;
        text-decoration: none;
        &:hover, &.active {
          color: $brand-secondary;
          text-decoration: none;
        }
      }
      &:last-child {
        padding-right: 0px;
      }
      &.expanded {
        cursor: pointer;
        display: block;
        ul {
          display: none;
          opacity: 1;
          position: absolute;
          left: 0;
          top: 79px;
          background: #FFF;
          float: none;
          width: 100%;
          @include transition(all 0.2s ease-in-out);
          opacity: 0;
          visibility: hidden;
          padding: 0;
          display: flex;
          flex: 1;
          li {
            width: 25%;
            padding: 20px;
            &:nth-child(1) {
              background: $green;
            }
            &:nth-child(2) {
              background: $blue;
            }
            &:nth-child(3) {
              background: $orange;
            }
            &:nth-child(4) {
              background: $purple;
            }
            &:nth-child(5) {
              background: $pink;
            }
            ul {
              position: static;
              opacity: 1;
              top: auto;
              display: block;
              top: auto;
              padding: 10px 0 0 0;
              -moz-transition: none;
              -webkit-transition: none;
              -o-transition: color 0 ease-in;
              transition: none;
              -moz-transform: none;
              -webkit-transform: none;
              transform: none;
              background: none;
              li {
                display: block;
                width: 100%;
                padding: 0;
                &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5){
                  background: none;
                }
                a, .nolink {
                  font-size: 13px;
                  padding: 3px 0;
                }
              }
            }
            a, .no-link {
              color: #fff;
              font-size: 15px;
              font-weight: 500;
              text-transform: none;
              letter-spacing: 0px;
              margin: 0;
              position: relative;
              display: block;
              line-height: 140%;
              &:hover, &.active {
                color: #000;
                text-decoration: none;
              }
            }
            &:last-child {
              border-bottom: none;
            }
          }
        }
        &.clicked {
          ul {
            margin: auto;
            opacity: 1;
            visibility: visible;
          }
        }
        &.service {
          position: relative;
          ul.menu {
            width: 300px;
            flex-wrap: wrap;
            top: 100%;
            li {
              width: 100%;
              background: #fff;
              a {
                color: #000;
              }
              &:hover {
                background: $xlightgrey;
              }
            }
          }
        }
      }
    }
  }
}

.main-header {
  background: #fff;
  .logo {
    margin-top: 20px;
    display: block;
  }
  &.scrolled {
    z-index: 110;
    height: 110px;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0 !important;
    padding: 5px 0;
    background: #fff;
    @include transition(height .2s);
    .logo {
      margin-top: 0;
      img {
        height: 90px;
      }
    }
    .top-line {
      display: none;
    }
    nav#main-nav {
      margin-top: 40px;
    }
  }
}
.mobile-menu {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 12;
  margin-top: 65px;
  padding: 10px 0 150px 0;
  left: 0;
  overflow-y: auto;
  @include transition(display .2s);
  > ul {
    margin: 0;
    padding: 0;
    > li {
      list-style: none;
      display: block;
      position: relative;
      > a {
        display: block;
        padding: 15px;
        font-size: 16px;
        text-transform: uppercase;
        text-decoration: none;
        border-bottom: 1px solid $xlightgrey;
        color: #000;
        font-weight: 600;
        &:hover, &:link, &:focus, &:active {
          color: #000;
        }
      }
      > ul {
        padding: 0;
        position: relative;
        left: -999px;
        height: 0;
        @include transition (left .5s ease-in-out);
        > li {
          padding: 15px;
          list-style: none;
          &:nth-child(1) {
              background: $green;
          }
          &:nth-child(2) {
            background: $blue;
          }
          &:nth-child(3) {
            background: $orange;
          }
          &:nth-child(4) {
            background: $purple;
          }
          &:nth-child(5) {
            background: $pink;
          }
          > a {
            text-decoration: none;
            font-weight: 600;
            color: #fff;
          }
          > ul {
            padding: 10px 0;
            > li {
              list-style: none;
              > a {
                display: block;
                text-decoration: none;
                padding: 5px 0;
                color: #fff;
              }
            }
          }
        }
      }
      &.clicked {
        ul {
          left: 0;
          height: auto;
        }
      }
      &.service {
        ul {
          li {
            background: #fff;
            a {
              color: #000;
            }
          }
        }
      }
    }
  }
  .facebook-link {
    margin: 20px 0;
    text-align: center;
    a.facebook {
      font-size: 40px;
    }
  }
}
body.open-menu {
  .mobile-menu {
    display: block;
  }
}
.trigger-menu {
  width: 40px;
  height: 25px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  margin: 15px auto 0 auto;
  position: absolute;
  right: 30px;
  top: 10px;
  z-index: 11;
  span {
    display: block;
    position: absolute;
    height: 5px;
    width: 100%;
    background: #fff;
    border-radius: 5px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    &:nth-child(1) {
      top: 0px;
    }
    &:nth-child(2) {
      top: 10px;
    }
    &:nth-child(3) {
      top: 20px;
    }
  }

  &.open {
    span {
      &:nth-child(1) {
        top: 10px;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
      }
      &:nth-child(2) {
        opacity: 0;
        left: -60px;
      }
      &:nth-child(3) {
        top: 10px;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
      }
    }
  }
}
