@media (max-width: 1199px) {
  section#video {
    .home-categories {
      .home-category {
        &.rouge {
          left: 12px;
          top: 337px;
        }
        &.bleu {
          left: 312px;
          top: 750px;
        }
        &.violet {
          top: 750px;
        }
        &.jaune {
          top: 637px;
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .video-foreground {
    width: 100%;
    left: 0;
    top: 0;
    background-image: url(../img/video-defaut.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,.2);
    }
    iframe {
      display: none;
    }
  }
}
@media (max-width: 991px) {
  section#video {
    .home-categories {
      .home-category {
        position: static;
        width: 50% !important;
        height: 300px !important;
        float: left;
      }
      h2 {
        position: static;
        text-align: center;
      }
    }
  }
  a.logo {
    img {
      width: 200px;
    }
  }
  nav#main-nav {
    ul {
      padding-left: 0;
      li {
        padding: 0 15px 20px 0;
        &.expanded {
          ul {
            top: 69px;
          }
        }
      }
    }
  }
  header {
    .navigation {
      a.fb-link {
        span {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .content-page {
    margin-top: 0;
    padding-top: 40px;
  }
  .video-foreground {
    width: 100%;
    left: 0;
    top: 0;
    background-image: url(../img/video-defaut.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,.2);
    }
    iframe {
      display: none;
    }
  }
  section#video {
    h1 {
      font-size: 2.5rem;
      padding-top: 50px;
    }
    h2 {
      font-size: 2rem;
      padding-bottom: 50px;
    }
    .home-categories {
      height: auto;
      .home-category {
        width: 100% !important;
        float: none;
        margin-bottom: 20px;
        &.vert, &.bleu, &.rouge, &.violet, &.jaune {
          h3 {
            width: auto;
            top: 0;
            left: 0;
            bottom: auto;
            font-size: 13px;
            padding: 5px;
          }
        }
        .overlay {
          position: relative;
        }
      }
    }
  }
  header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: none;
    padding: 0;
    &.scrolled {
      padding: 0;
    }
    .navigation {
      .fb-link {
        display: none;
      }
    }
  }
  #main {
    overflow-x: hidden;
    padding-top: 40px;
    margin-top: 0;
    &.main-scroll {
      margin: 0;
    }
  }
  body.open-menu {
    #main {
      overflow-y: hidden;
      height: 0;
    }
  }
  #references {
    h2 {
      padding-left: 0;
      text-align: center;
    }
  }
  .swiper-container {
    .pag-box {
      left: 0;
    }
  }
  footer {
    p {
      text-align: center;
    }
  }
  #main-nav {
    display: none;
  }
  .content-page {
    &:before {
      display: none;
    }
  }
  h1.main-h1 {
    font-size: 30px;
    width: auto;
    margin: 100px 0 0 0;
    text-align: center;
  }
  .imgleft {
    float: none;
    margin: 0;
  }
  .banner-page {
    .banner {
      height: 350px;
    }
  }
}
