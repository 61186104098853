// -------------------------------------------------------------
//   Paragraphs item galerie
// -------------------------------------------------------------

.content-galerie {
  height: 600px;
  position: relative;
  -webkit-box-shadow: 10px 10px 0px -2px rgba(209,216,221,1);
  -moz-box-shadow: 10px 10px 0px -2px rgba(209,216,221,1);
  box-shadow: 10px 10px 0px -2px rgba(209,216,221,1);
  .swiper-galerie {
      width: 100%;
      height: 100%;
      overflow:hidden;
      .swiper-slide {
        width: 100%;
        height: 100%;
        .lebg {
          width: 100%;
          height: 100%;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          position: absolute;
          opacity: 0.5;
          background-position: 80% 50%;
          background-size: cover;
        }
      }
      .swiper-buttons {
        position: absolute;
        bottom: 20px;
        text-align: center;
        z-index: 10;
        width: 100%;
        .swiper-block {
          display: inline-block;
          background: #fff;
          -webkit-border-radius: 50px;
          -moz-border-radius: 50px;
          border-radius: 50px;
          .swiper-button-prev, .swiper-button-next {
            position: static;
            margin: auto;
            height: auto;
            display: inline-block;
            padding: 5px 10px;
            &:after {
              display: inline-block;
              font-family: FontAwesome;
              font-size: 14px;
              color: $brand-primary;
              text-align: center;
            }
          }
        }
      }
      .swiper-button-next {
        background-image: none;
        width: auto;
        &:after {
          content: '\f054';
        }
      }
      .swiper-button-prev {
        background-image: none;
        width: auto;
        &:after {
          content: '\f053';
        }
      }
  }
}