// -------------------------------------------------------------
//   Main layout
//   Defines basic main styles in layout
// -------------------------------------------------------------
body {
  font-family: $font-family-sans-serif;
  margin: 0;
}
.video-background {
  background: #000;
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: -99;
}
.video-foreground,
.video-background iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
@media (min-aspect-ratio: 16/9) {
  .video-foreground { height: 300%; top: -100%; }
}
@media (max-aspect-ratio: 16/9) {
  .video-foreground { width: 300%; left: -100%; }
}
@media all and (max-width: 600px) {
  .vid-info { width: 50%; padding: .5rem; }
  .vid-info h1 { margin-bottom: .2rem; }
}
@media all and (max-width: 500px) {
  .vid-info .acronym { display: none; }
}
#main{
  z-index: 1;
  position: relative;
  margin-top: 60px;
  &.main-scroll {
    margin-top: 60px;
  }
}
footer.footer {

}
h1 {
  margin: 50px 0 40px 0;
}
h1.main-h1 {
  color: #fff;
  font-size: 50px;
  text-transform: uppercase;
  font-weight: 600;
  margin: 150px 0 0 0;
}
h1.simple-h1 {
  margin-top: 40px;
}
h2 {
  font-weight: 300;
  font-size: 24px;
  color: $brand-primary;
  margin-bottom: 40px;
  margin-top: 20px;
  line-height: 35px
}
h3 {
  font-size: 20px;
  font-weight: 500;
  color: $blue;
}
h4 {
  font-size: 18px;
  font-weight: 500;
  color: $blue;
}
p {
  font-size: 14px;
  line-height: 24px;
  color: $middlegrey;
}
a {
  color: #000;
  text-decoration: underline;
}
.imgleft {
  float: left;
  margin: 0 40px 10px 0;
}
.main-title {
  position: relative;
  height: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  h1 {
    font-weight: 300;
    color: #fff;
    font-size: 36px;
    z-index: 2;
  }
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,.4);
    z-index: 1;
  }
}
.modal {
  background: rgba(0,0,0,0.8);
}
.modal-dialog {
  width: 800px;
}
.modal-content {
  border-radius: 0;
  background: transparent;
}
.modal-body {
  background: none;
  padding: 0;
  border-radius: 0;
  text-align: center;
}
.login-box {
  background: $xlightgrey;
  padding: 40px;
  margin-top: 50px;
}
.breadcrumb {
  display: none;
}
.banner-page {
  .banner {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 500px;
    position: relative;
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,.3);
    }
  }
}
.content-page {
  background: #fff;
  margin-top: -200px;
  padding: 80px 20px;
  position: relative;
  &:before {
    background: #fff;
    content: '';
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 0;
    left: 0;
    margin-left: -50%;
  }
}
.form-container {
  margin-top: 30px;
}
body.adminimal-menu {
  header {
    top: 29px;
  }
  main {
    margin-top: 89px;
  }
}
