#references.references {
  h2 {
  	font-size: 14px;
  	span {
  		font-size: 22px;
  		display: block;
  		&.rouge {
  			color: $pink;
  		}
  		&.violet {
  			color: $purple;
  		}
  		&.jaune {
  			color: $orange;
  		}
  		&.bleu {
  			color: $blue;
  		}
  		&.vert {
  			color: $green;
  		}
  	}
  }
}
.product-list {
  margin-bottom: 40px;
  text-align: center;
  padding-bottom: 20px;
  height: 420px;
  @include transition (box-shadow .5s ease-in-out);
  .pic {
    height: 220px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  h2 {
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 24px;
  }
  &:hover {
    -webkit-box-shadow: 0px 5px 16px 2px rgba(219,219,219,1);
    -moz-box-shadow: 0px 5px 16px 2px rgba(219,219,219,1);
    box-shadow: 0px 5px 16px 2px rgba(219,219,219,1);
  }
}
